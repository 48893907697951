import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import countryList from 'react-select-country-list';
import { Modal } from 'components/elements/Modal';
// import { useOrdersQuery } from 'graphql/queries/orders/__generated__/getOrder.generated';
import { isEmptyObject } from 'helpers/common';
import { ScOrderModal } from './styled';
import { getFullDate } from 'helpers/dayjs/formats';
import { useGetClassTimeLazyQuery } from 'graphql/queries/attendees/__generated__/getClassTime.generated';
import { Cross } from 'components/elements/Button/Cross';
import { useCancelOrderMutation } from 'graphql/mutations/orders/__generated__/cancelOrder.generated';
import { errorAlert, infoAlert, warningAlert } from 'helpers/alerts';
import { useModal } from 'hooks/useModal';
import { MoveAttendeeModal } from '../MoveAttendeeModal';
import { useConfirmPaymentMutation } from 'graphql/mutations/attendees/__generated__/confirmPayment.generated';
import { Spinner } from 'components/elements/Spinner';
import BillingInformation from './BillingInformation';
import EmailLog from './EmailLog';
import MainInfo from './MainInfo';
import { OrderEntity } from 'types.generated';
import ArrowSvg from 'theme/images/arrowLeft.svg';
import { Transition } from '@headlessui/react';
import { getSiteTheme } from 'hooks/getSiteTheme';
import { useGetOrderByIdQuery } from 'graphql/queries/orders/__generated__/getOrderById.generated';
// import { useResendEmailMutation } from 'graphql/mutations/orders/__generated__/resendEmail.generated';

export interface OrderModallProps {
  orderId: string;
  activityId: string;
  classesCount: number;
  onClose: () => void;
  refetch: any;
}

export const OrderModal = forwardRef<HTMLDivElement, OrderModallProps>(
  (props, ref) => {
    const { orderId, activityId, onClose, classesCount, refetch } = props;

    const [isBillingInfoVisible, setIsBillingInfoVisible] = useState(true);
    console.log(orderId, 'orderId');
    const { onMoveAttendeeModal, isOpenMoveAttendeeModal } = useModal();
    // const { bookingsRefetch } = usePageElemetns();

    // const { data: allOrders, refetch: orderRefetch } = useOrdersQuery({
    //   variables: {
    //     params: {
    //       activityId,
    //     },
    //   },
    // });

    const { data: allOrders, refetch: orderRefetch } = useGetOrderByIdQuery({
      variables: {
        orderByIdId: orderId,
      },
    });

    // console.log(allOrders, 'allOrders')

    const [confirmPayment, { loading: loadingConfirmPayment }] =
      useConfirmPaymentMutation();

    const [confirmLoading, setConfirmLoading] = useState(false);

    const [cancelOrder] = useCancelOrderMutation();

    // const [resendEmail] = useResendEmailMutation();

    const [getClassTime] = useGetClassTimeLazyQuery();

    const [classInfo, setClassInfo] = useState({
      start: '',
      end: '',
    });

    const order = useMemo(() => allOrders?.OrderById, [allOrders]);

    // const order = useMemo(() => {
    //   if (allOrders != null) {
    //     const i = allOrders.OrderById.map((item, index) => ({
    //       ...item,
    //     })).find((item) => item.id === orderId);
    //     if (!isEmptyObject(i) && i != null) return i;
    //   }
    // }, [allOrders, orderId]);

    const countries = useMemo(() => countryList().getData(), []);

    const billingInfo = useMemo(() => {
      if (order != null) {
        return [
          {
            title: 'Name',
            value: order.firstName as string,
          },
          {
            title: 'Surname',
            value: order.lastName as string,
          },
          {
            title: 'Email address',
            value: order.attendee?.email as string,
          },
          {
            title: 'Phone',
            value: order.phoneNumber as string,
            underline: true,
          },
          {
            title: 'Country',
            value: countries.find((c) => c.value === order?.country)
              ?.label as string,
          },
          {
            title: 'Postcode',
            value: order.postcode as string,
          },
          {
            title: 'Date',
            value: getFullDate(order.createdDate),
          },
        ];
      }
    }, [order, countries]);

    useEffect(() => {
      if (order != null) {
        getClassTime({
          variables: { activityId: `${order.activityId}` },
        }).then(({ data }) => {
          if (data != null) {
            const i = data.ActivityById.activity_classes.find(
              (c) => c.id === order.activityClassId
            );
            if (!isEmptyObject(i) && i != null) {
              //@ts-ignore
              setClassInfo(i);
            }
          }
        });
      }
    }, [getClassTime, order]);

    const handleCancelOrder = useCallback(() => {
      if (
        order != null &&
        window.confirm('Are you sure you want to cancel this booking?')
      ) {
        cancelOrder({ variables: { cancelOrderId: order.id } }).then(
          ({ data, errors }) => {
            if (data != null) {
              refetch[0]();
              refetch[1]();
              orderRefetch();
              onClose();
              infoAlert('Order canceled!');
            }
            if (errors != null) {
              warningAlert(errors[0].message);
            }
          }
        );
      }
    }, [cancelOrder, onClose, order, refetch, orderRefetch]);

    // const handleResendEmail = useCallback(
    //   (id) => {
    //     resendEmail({ variables: { id: id } }).then(({ data }) => {
    //       if (data != null) infoAlert('Resending successful!');
    //     });
    //   },
    //   [resendEmail]
    // );

    //Move attendee

    const handleOpenMoveAttendeeModal = useCallback(() => {
      if (classesCount > 1) {
        onMoveAttendeeModal();
      } else {
        errorAlert('In this activity just one class');
      }
    }, [classesCount, onMoveAttendeeModal]);

    const handleConfirmPayment = () => {
      setConfirmLoading(true);
      confirmPayment({
        variables: {
          confirmPaymentId: orderId,
        },
      }).then(({ data }) => {
        if (data != null) {
          setTimeout(() => {
            infoAlert('Payment confirmed!');
            orderRefetch();
            refetch();
            setConfirmLoading(false);
          }, 2000);
        }
      });
    };
    console.log(isBillingInfoVisible, 'isBillingInfoVisible');

    const { isLight } = getSiteTheme();

    return (
      <ScOrderModal className={`${!isLight && '!bg-zinc-900'}`} ref={ref}>
        <Cross onClick={onClose} size={26} />
        <div
          className="lg:hidden absolute lg:static top-[10px] right-[70px] z-10 transition-rotate duration-200"
          style={{ rotate: isBillingInfoVisible ? '180deg' : '0deg' }}
          onClick={() => setIsBillingInfoVisible(!isBillingInfoVisible)}
        >
          <img
            src={ArrowSvg}
            width={22}
            className="bg-white rounded-full"
            alt="ArrowSvg"
          />
        </div>

        {order != null ? (
          <>
            <MainInfo
              {...{
                order: order as OrderEntity,
                handleCancelOrder,
                handleOpenMoveAttendeeModal,
                classInfo,
                handleConfirmPayment,
                confirmLoading,
                loadingConfirmPayment,
                refetch: orderRefetch,
              }}
            />
            <Transition
              show={isBillingInfoVisible}
              enter="transition ease-out duration-300"
              enterFrom="transform opacity-0 translate-x-full"
              enterTo="transform opacity-100 translate-x-0"
              leave="transition ease-in duration-200"
              leaveFrom="transform opacity-100 translate-x-0"
              leaveTo="transform opacity-0 translate-x-full"
              className={`absolute lg:static right-0 ${
                isLight
                  ? 'bg-white border-l-grey'
                  : 'bg-zinc-800 border-l-zinc-700'
              } border-l-[1px] h-full top-[-5px]`}
            >
              <div className="overflow-y-scroll h-full">
                <BillingInformation billingInfo={billingInfo} />
                <EmailLog
                  order={order as OrderEntity}
                  // handleResendEmail={handleResendEmail}
                />
              </div>
            </Transition>
          </>
        ) : (
          <Spinner size={64} />
        )}

        {isOpenMoveAttendeeModal && order != null && (
          <Modal onClose={onMoveAttendeeModal} alpha={0.5}>
            {(props) => (
              <MoveAttendeeModal
                {...props}
                orderId={orderId}
                activityId={activityId}
                classId={`${order.activityClassId}`}
                attendeeName={order.firstName + ' ' + order.lastName}
                onClose={onMoveAttendeeModal}
                refetch={refetch}
                orderRefetch={orderRefetch}
              />
            )}
          </Modal>
        )}
      </ScOrderModal>
    );
  }
);
