import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderByIdQueryVariables = Types.Exact<{
  orderByIdId: Types.Scalars['String'];
}>;

export type GetOrderByIdQuery = {
  __typename?: 'Query';
  OrderById: {
    __typename?: 'OrderEntity';
    id: string;
    createdDate?: any | null;
    updatedDate?: any | null;
    deletedDate?: any | null;
    spendInCents: number;
    phoneNumber?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    postcode?: string | null;
    country?: string | null;
    status: string;
    paymentIntentId?: string | null;
    currency: string;
    activityTitle: string;
    activityClassTitle: string;
    attendeeId?: string | null;
    activityId?: string | null;
    activityClassId?: string | null;
    organizationId: string;
    checkoutFields: any;
    start?: any | null;
    orderNumber?: number | null;
    invoiceNumber?: string | null;
    paymentType: string;
    comments?: Array<{
      __typename?: 'CommentEntity';
      id: string;
      createdDate?: any | null;
      text: string;
      userId: string;
      orderId: string;
      user?: {
        __typename?: 'UserEntity';
        email: string;
        name?: string | null;
      } | null;
    }> | null;
    attendee?: {
      __typename?: 'AttendeeEntity';
      country?: string | null;
      postcode?: string | null;
      name?: string | null;
      email: string;
      id: string;
    } | null;
    organization?: { __typename?: 'OrganizationEntity'; id: string } | null;
  };
};

export const GetOrderByIdDocument = gql`
  query GetOrderById($orderByIdId: String!) {
    OrderById(id: $orderByIdId) {
      id
      createdDate
      updatedDate
      deletedDate
      spendInCents
      phoneNumber
      firstName
      lastName
      postcode
      country
      status
      paymentIntentId
      currency
      activityTitle
      activityClassTitle
      attendeeId
      activityId
      activityClassId
      organizationId
      checkoutFields
      start
      orderNumber
      invoiceNumber
      paymentType
      comments {
        id
        createdDate
        text
        userId
        orderId
        user {
          email
          name
        }
      }
      attendee {
        country
        postcode
        name
        email
        id
      }
      organization {
        id
      }
    }
  }
`;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      orderByIdId: // value for 'orderByIdId'
 *   },
 * });
 */
export function useGetOrderByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export function useGetOrderByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderByIdQuery,
    GetOrderByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(
    GetOrderByIdDocument,
    options
  );
}
export type GetOrderByIdQueryHookResult = ReturnType<
  typeof useGetOrderByIdQuery
>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrderByIdLazyQuery
>;
export type GetOrderByIdQueryResult = Apollo.QueryResult<
  GetOrderByIdQuery,
  GetOrderByIdQueryVariables
>;
